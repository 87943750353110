import React, { useContext } from "react";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";
import ApplicationTitle from "./application-title";
import LoanApplicantInformation from "./loan-applicant-information";
import LoanApplicantInvite from "./loan-applicant-invite";
import { FinanceContext } from "../../../../context/finance-context";
import { getFullName } from "../../../../utils/user-data-util";

const LoanApplicant = () => {
  const { data: userInfo } = useMyInfo();
  const { id } = userInfo;

  const { selectedScenarioDetail } = useContext(FinanceContext);

  const { jointScenarioConnections } = selectedScenarioDetail;
  const isOwner = selectedScenarioDetail.mainUserId === id;

  return (
    <div className="flex justify-between" style={{ gap: 12, flexWrap: "wrap" }}>
      {jointScenarioConnections.map((connection) => {
        return (
          <div key={connection.id}>
            <ApplicationTitle
              title={`${connection?.member?.firstName} Main Applicant`}
            />
            <LoanApplicantInformation
              name={getFullName(connection?.member)}
              phone={connection?.member?.phoneNumber}
              email={connection?.email}
              status={connection?.status}
              connection={connection}
              scenarioId={selectedScenarioDetail?.id}
              isOwner={
                connection.memberId === selectedScenarioDetail.mainUserId
              }
            />
          </div>
        );
      })}
      {jointScenarioConnections.length < 2 && isOwner && (
        <div>
          <ApplicationTitle title="Invite Other to Application" />
          <LoanApplicantInvite />
        </div>
      )}
    </div>
  );
};

export default LoanApplicant;
